import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
// import { MandatoryRuleConfig } from '@cloudpivot-hermes/model-designer/lib';
let ModelConfig = class ModelConfig extends Vue {
    get appName() {
        return this.$route.query.appName;
    }
    get appCode() {
        return this.$route.params.appCode;
    }
    backHome() {
        this.$router.push({
            name: 'app-installed',
        });
    }
    backDetail() {
        this.$router.push({
            name: 'app-detail',
            query: {
                appName: this.appName,
            },
        });
    }
};
ModelConfig = __decorate([
    Component({
        name: 'ModelConfig',
        components: {
        // MandatoryRuleConfig,
        },
    })
], ModelConfig);
export default ModelConfig;
